import React, { useState } from 'react'
import { shape, string, arrayOf, object } from 'prop-types'
import { graphql } from 'gatsby'

import LocalizationContext, { LOCALES } from '../../../components/LocalizationContext'
import MetaData from '../../../components/common/MetaData'
import ShopLayout from '../../../components/shop/ShopLayout'
import { ImageViewer } from '../../../components/common/ImageViewer/ImageViewer'
import Button from '../../../components/common/Button'

import styles from '../../../styles/product.module.css'

const DoucesSoupesPage = ({ location, data }) => {
    const [quantity, setQuantity] = useState(1)

    const ebookPrice = 14.9.toLocaleString(`fr`, { style: `currency`, currency: `eur` })
    const paperbackPrice = 24.9.toLocaleString(`fr`, { style: `currency`, currency: `eur` })

    return (
        <LocalizationContext.Provider value={{ locale: LOCALES.FR }}>
            <MetaData location={location} image={data.allFile.edges[0].node.childImageSharp.fixed.src} />
            <ShopLayout pathname={location.pathname}>
                <div className={styles.productDetails}>
                    <div>
                        <ImageViewer images={data.allFile.edges.map(item => item.node)} />
                    </div>
                    <aside>
                        <h1 className={styles.title}>Douces Soupes</h1>
                        <p>
                            Un beau livre qui regroupe 25 recettes de soupes pour l’automne et l’hiver.
                        </p>
                        <p className={styles.price}>
                            À partir de <span className={styles.priceNumber}>{ebookPrice}</span>
                        </p>
                        <div className={styles.variants}>
                            <label>
                                Qté<br/>
                                <input type="number" value={quantity} onChange={e => setQuantity(Number(e.target.value))} />
                            </label>
                            <div>
                                <Button
                                    variant="primary"
                                    className="snipcart-add-item"
                                    data-item-id="B-FR-00001"
                                    data-item-price="24.9"
                                    data-item-url="/fr/livres/douces-soupes"
                                    data-item-description="Un beau livre qui regroupe 25 recettes de soupes pour l’automne et l’hiver"
                                    data-item-image={data.allFile.edges[0].node.childImageSharp.fixed.src}
                                    data-item-name="Douces Soupes - Version Papier - Français"
                                    data-item-taxable="false"
                                    data-item-quantity={quantity}
                                    data-item-weight="228"
                                    data-item-length="26"
                                    data-item-width="18"
                                    data-item-height="1"
                                >
                                    Commander le livre ({paperbackPrice})
                                </Button>
                                <Button
                                    variant="primary"
                                    className="snipcart-add-item"
                                    data-item-id="EB-FR-00001"
                                    data-item-price="14.9"
                                    data-item-url="/fr/livres/douces-soupes"
                                    data-item-description="Un magnifique ebook qui regroupe 25 recettes de soupes pour l’automne et l’hiver"
                                    data-item-image={data.allFile.edges[0].node.childImageSharp.fixed.src}
                                    data-item-name="Douces Soupes - Version PDF - Français"
                                    data-item-taxable="false"
                                    data-item-file-guid="b7c3781f-9606-481f-8fa8-6e3b1904007c"
                                    data-item-max-quantity="1"
                                >
                                    Acheter le ebook (PDF) ({ebookPrice})
                                </Button>
                            </div>
                        </div>
                    </aside>
                </div>
                <hr className={styles.divider} />
                <section className={styles.desc} >
                    <p>
                        Douces soupes est le premier livre auto-édité de Maëva Cherrier. Il est disponible au format papier et numérique (PDF).
                    </p>
                    <p>
                        Il est composé de 25 recettes de soupes qui respectent la saisonnalité des produits de l’automne et de l’hiver. Vous découvrirez comment réaliser des soupes simples et d’autres plus sophistiquées, à utiliser des épices et des herbes pour relever les saveurs et les complimenter.
                    </p>
                    <p>
                        Les photos en très haute résolution, parsemées dans le livre par l’auteure, vous donnerons envie de découvrir par vous même ce qui fait la renommée de Travel & Food depuis tant d’années.
                    </p>
                    <p>
                        La version numérique du livre peut être acheté séparément.
                    </p>
                </section>
                <hr className={styles.divider} />
                <section className={styles.infos}>
                    <div>
                        <h3>Contenu</h3>
                        <ul>
                            <li>Langue: Française</li>
                            <li>Auteur: Maëva Cherrier</li>
                            <li>Photographe: Maëva Cherrier</li>
                            <li>Nombre de pages: 60 pages</li>
                            <li>Nombre de recettes: 25 recettes</li>
                            <li>ISBN: 979-1-0699-3341-5</li>
                        </ul>
                    </div>
                    <div>
                        <h3>Version numérique</h3>
                        <ul>
                            <li>Format: PDF</li>
                            <li>Taille: 18 Mo</li>
                            <li>Définition: 144 dpi</li>
                        </ul>
                    </div>
                    <div>
                        <h3>Version papier</h3>
                        <ul>
                            <li>Couverture: couverture souple</li>
                            <li>Poid: 228g</li>
                            <li>Hauteur: 26cm</li>
                            <li>Largeur: 18cm</li>
                            <li>Épaisseur: 0,6cm</li>
                        </ul>
                    </div>
                </section>
            </ShopLayout>
        </LocalizationContext.Provider>
    )
}

DoucesSoupesPage.propTypes = {
    location: shape({
        pathname: string.isRequired,
        search: string.isRequired,
    }).isRequired,
    data: shape({
        allFile: shape({
            edges: arrayOf(shape({
                node: object,
            })),
        }),
    }),
}

export default DoucesSoupesPage

export const query = graphql`
    {
        allFile(filter: {relativePath: {regex: "/^douces-soupes/"}}, sort: {order: ASC, fields: name}) {
            edges {
                node {
                    childImageSharp {
                        fixed(width: 280) {
                            ...GatsbyImageSharpFixed
                        }
                        smallFixed: fixed(height: 50) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }
`
