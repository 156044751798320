import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import styles from './ImageViewer.module.css'

export const ImageViewer = (props) => {
    const [slectedImage, setSelectedImage] = useState(props.images[0].childImageSharp.fixed)

    return (
        <div className={styles.container}>
            <div className={styles.viewer}>
                <Img fixed={slectedImage} />
            </div>
            <div className={styles.selector}>
                {props.images.map((item, index) => (
                    <button key={index} className={slectedImage === item.childImageSharp.fixed ? styles.buttonSelected : styles.button} onClick={() => setSelectedImage(item.childImageSharp.fixed)}>
                        <Img className={styles.buttonImage} fixed={item.childImageSharp.smallFixed} />
                    </button>
                ))}
            </div>
        </div>
    )
}

ImageViewer.propTypes = {
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
}
